<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" transition="scroll-y-transition" :max-width="$vuetify.breakpoint.smAndDown ? '94vw' : 475" :right="$vuetify.breakpoint.smAndUp" :left="$vuetify.breakpoint.smAndDown" offset-y attach :z-index="1001">
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined color="secondary" class="rounded-lg" :ripple="false" v-bind="attrs" v-on="on">
          <v-icon left color="secondary">
            mdi-map-marker
          </v-icon>
          <span v-if="!loading" class="text-subtitle-1 text-capitalize font-weight-medium"><strong>{{ getValueInCookie(true) || i18n.t('notLocated') }}</strong></span>
          <v-skeleton-loader v-if="loading" width="125px" height="28px" type="text" class="d-flex align-center"></v-skeleton-loader>
          <v-icon right x-small color="grey darken-3">
            {{ menu ? 'mdi-triangle' : 'mdi-triangle-down' }}
          </v-icon>
        </v-btn>
      </template>
      <v-card outlined class="border-10" :style="{ border: `1px solid ${$vuetify.theme.themes.light.secondary} !important`, backgroundColor: $vuetify.theme.currentTheme.lightsecondary }">
        <v-card-title class="d-flex justify-center pa-2 pb-0">
            <v-spacer></v-spacer>
            <v-icon right @click="closeMenu">mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-if="showRedirectCard()" class="pa-6 pt-0">
          <v-card flat color="grey lighten-3" width="100%" class="d-flex column-row mr-4">
            <v-col cols="auto" class="pr-0">
              <v-icon color="secondary">mdi-map-marker-multiple</v-icon>
            </v-col>
            <v-col class="pl-0">
              <v-card-title>
                <p class="ma-0 break-word">{{ i18n.t('looksLikeYoureIn') }} <span class="text-capitalize">{{ $cookies.get(COOKIENAME)?.seoCity?.toLowerCase() || $cookies.get(COOKIENAME)?.city?.toLowerCase() }}</span>!</p>
              </v-card-title>
              <v-card-text>
                <p class="ma-0 break-word">{{ i18n.t('navigateToThe') }} <span class="text-capitalize">{{ $cookies.get(COOKIENAME)?.seoCity?.toLowerCase() || $cookies.get(COOKIENAME)?.city?.toLowerCase() }}</span> {{ i18n.t('pageForLocalInsights') }}.</p>
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" class="rounded-lg text-subtitle-1 text-capitalize custom-shadow" @click="gTagSendEvents('Search - Go To', `${$cookies.get(COOKIENAME)?.zip}`, `select_content`, `engagement`)" :href="getURLInCookie()">
                  <p class="ma-0">{{ i18n.t('goTo', $cookies.get(COOKIENAME)?.seoCity?.toLowerCase() || $cookies.get(COOKIENAME)?.city?.toLowerCase()) }}</p>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-card>
        </v-card-title>
        <v-card-title class="px-5 py-0 pb-1">
            <span class="text-subtitle-1 font-weight-bold grey--text text--darken-2">{{ i18n.t('myLocation') }}</span>
        </v-card-title>
        <v-card-text class="px-5 pb-0">
          <v-form ref="searchForm" lazy-validation @submit.prevent>
            <v-text-field v-model="searchValue" :placeholder="i18n.t('enterZipCode')" outlined dense hide-details="auto" color="secondary" class="rounded-lg" :readonly="readOnly" :rules="formRules.zipRules" :error-messages="displayErrorMessage" @keydown.enter="setCookieValue" @focus="clearInput">
              <template v-slot:prepend-inner>
                <v-icon color="secondary">mdi-map-marker</v-icon>
              </template>
              <template v-slot:append-outer>
                <v-slide-x-transition v-if="!$vuetify.breakpoint.smAndDown" leave-absolute>
                  <v-btn @click="setCookieValue" outlined color="secondary" height="40px" class="mt-0 rounded-lg text-subtitle-1 text-capitalize custom-shadow" :loading="loading" :disabled="readOnly">
                    <strong>{{ i18n.t('updateLocation') }}</strong>
                  </v-btn>
                </v-slide-x-transition>
              </template>
              <template v-slot:append>
                <v-icon v-if="searchValue&&!displayErrorMessage" color="secondary" @click="clearInput">mdi-pencil-box-outline</v-icon>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-5 pb-5">
          <v-slide-x-transition v-if="$vuetify.breakpoint.smAndDown" leave-absolute>
            <v-btn @click="setCookieValue" outlined block color="secondary" height="40px" class="mt-0 rounded-lg text-subtitle-1 text-capitalize custom-shadow" :loading="loading" :disabled="readOnly">
              <strong>{{ i18n.t('updateLocation') }}</strong>
            </v-btn>
          </v-slide-x-transition>
        </v-card-actions>
      </v-card>
    </v-menu>
</div>
</template>

<script>
import i18n from "@/i18n";
import { districts_list } from '../plugins/districts';
import pako from 'pako';
export default {
  name: 'LocationSearch',
  props: {
    redirect: Boolean,
    lang: String,
  },
  data() {
    return {
      i18n: i18n,
      loading: false,
      menu: false,
      readOnly: false,
      displayErrorMessage: '',
      searchValue: '',
      COOKIENAME: 'userLocation',
      locationData: {},
    };
  },
  computed: {
    formRules() {
      return {
        zipRules: [
            v => !!v || this.i18n.t('formRules.zip.required'),
            v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.zip.nbCaracters'),
            v => /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z]\s?\d[A-Z]\d)$/i.test(v) || this.i18n.t('formRules.zip.required'),
        ],
      }
    }
  },
  async mounted() {
    i18n.locale = this.lang;
    if(this.$cookies.isKey(this.COOKIENAME)){
      this.loading = true;
      this.searchValue = this.getValueInCookie();
      await this.fetchMuleAPIDev(this.$cookies.get(this.COOKIENAME)?.zip);
      this.editPhoneNumberInHTML(this.getPhoneInCookie());
    }
  },
  methods: {
    async setCookieValue() {
      if(!this.readOnly && this.$refs.searchForm.validate()){
        this.gTagSendEvents('Search - Update Location', this.$cookies.get(this.COOKIENAME)?.zip, "select_content", "engagement");
        await this.fetchMuleAPIDev();
        this.editPhoneNumberInHTML(this.getPhoneInCookie());
      }
    },
    getValueInCookie(hideCity) {
      const { city, seoCity, zip } = this.$cookies.get(this.COOKIENAME) || {};
      if (!zip) return null;

      const formattedZip = this.isCanadianPostalCode(zip)
        ? zip.toUpperCase().substring(0, 3) // Only the first three characters for Canadian postal codes
        : zip.toUpperCase(); // Full zip code for others

      return `${formattedZip}${hideCity ? '' : ` - ${this.capitalizeText(seoCity || city)}`}`;
    },
    getURLInCookie() {
      const URL = this.$cookies.get(this.COOKIENAME)?.url;
      return URL && URL !== window.location.hostname ? URL : null;
    },
    getPhoneInCookie() {
      const PHONE = this.$cookies.get(this.COOKIENAME)?.phone;
      return PHONE || null;
    },
    editPhoneNumberInHTML(newNumber) {
      const paramPhoneNumber = new URLSearchParams(window.location.search).get('pn');
      document.querySelectorAll('.rp_phone-number').forEach(element => {
        if (!paramPhoneNumber) {
          element.innerText = newNumber;
          element.closest('a[href^="tel:"]')?.setAttribute('href', `tel:+1${newNumber.replace(/\D/g, '')}`);
        }
      });
    },
    async fetchMuleAPIDev(useZipFromCookie) {
      this.loading = true;
      this.displayErrorMessage = '';
      try {
        // Get the zip code from the cookie or search value
        const zipCode = useZipFromCookie || this.searchValue;

        // Handle empty zip code case
        if (!zipCode) {
          this.displayErrorMessage = ''; // Clear error message
          this.searchValue = ''; // Reset the search value
          return; // Exit the function early
        }

        const mappingResponse = await fetch(`${process.env.VUE_APP_ASSETS_URL}/website-district-zip-mapping/hauling-district-zip-mapping.json.gz`, {
          method: "GET",
        });

        // Get the compressed data
        const compressedData = await mappingResponse.arrayBuffer();

        // Decompress the gzipped data
        const decompressedData = pako.ungzip(new Uint8Array(compressedData), { to: 'string' });
        const zipMapping = JSON.parse(decompressedData)

        // Check if the zip code exists in the mapping
        const district = zipMapping[this.isCanadianPostalCode(zipCode) ? zipCode.substring(0, 3).toUpperCase() : zipCode];

        if (district) {
          // Make the API call using the mapped district
          const response = await fetch(`${process.env.VUE_APP_ASSETS_URL}/WebSiteJsons/${district}.json`, {
            method: "GET",
          });

          const data = await response.json();
          this.locationData = data[0];
          const COOKIEDATA = {
            zip: zipCode,
            city: this.locationData.SiteDetail.SiteAddress.City,
            seoCity: this.locationData.SEOData.SEOCity,
            url: this.locationData.ContactDetails.Website,
            phone: this.locationData.ContactDetails.CSPhone
          };
          this.$cookies.set(this.COOKIENAME, COOKIEDATA);
          this.searchValue = this.getValueInCookie();
          this.$refs.searchForm?.resetValidation();
          this.setReadOnly(true);
          await this.redirectRules(!useZipFromCookie);
        } else {
          this.displayErrorMessage = this.i18n.t('outsideServiceArea');
        }
      } catch (error) {
        this.displayErrorMessage = this.i18n.t('apiError');
      } finally {
        this.loading = false;
      }
    },
    async fetchMuleAPIDevFromAddress(url) {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_MULEAPIDEV_URL}/experience/site/Summary?fromAddress=${url}`, {
            method: "GET",
            headers: {
              "Authorization": "2236f4cfa96f20af2ca8cfdf9c5920f54d78f1b835318d729cgc2a72403cc29",
            },
          }
        );
        const data = await response.json();
        const hostPathUrl = `${process.env.VUE_APP_WASTE_CONNECTIONS_URL}${window.location.pathname}`.replace(/\/+$/, '');

        if (data[0]?.errorMessage) return false;

        const website = data[0]?.ContactDetails?.Website || '';
        const updatedWebsite = website.startsWith('http://')
          ? website.replace('http://', 'https://')
          : website.startsWith('https://')
            ? website
            : 'https://' + website;

        const finalWebsite = updatedWebsite.replace(/\/+$/, '');

        return hostPathUrl === finalWebsite;
      } catch (error) {
        this.displayErrorMessage = this.i18n.t('apiError');
      } finally {
        this.loading = false;
      }
    },
    async redirectRules(shouldRedirect) {
      if(this.redirect && this.getURLInCookie()){
        await this.pageRedirect(shouldRedirect);
      }
    },
    async pageRedirect(shouldRedirect) {
      const { pathname } = window.location;
      const hostPathUrl = `${process.env.VUE_APP_WASTE_CONNECTIONS_URL}${pathname}/`;
      const urlOfUserLocation = new URL(this.getURLInCookie());
      const isUrlMatchAPIUrl = await this.fetchMuleAPIDevFromAddress(hostPathUrl);

      if (shouldRedirect && urlOfUserLocation.pathname !== pathname && isUrlMatchAPIUrl) {
        this.gTagSendEvents('Search - Page Redirect', this.$cookies.get(this.COOKIENAME)?.zip, "select_content", "engagement");
        window.location.href = urlOfUserLocation.href;
      }
    },
    showRedirectCard() {
      const { href, pathname, hostname } = window.location;

      // Check if the hostname matches the specific hostname
      if (hostname !== 'www.wasteconnections.com') {
        return false;
      }

      const pathSegment = pathname.split('/').filter(Boolean)[0];
      const cookieURL = this.getURLInCookie();
      const urlOfUserLocation = cookieURL ? new URL(cookieURL).href : null;

      return cookieURL && href !== urlOfUserLocation && !(pathSegment in districts_list);
    },
    setReadOnly(value) {
      this.readOnly = value;
    },
    closeMenu() {
      this.menu = false;
    },
    clearInput() {
      this.searchValue = '';
      this.displayErrorMessage = '';
      this.setReadOnly(false);
    },
    capitalizeText(text) {
      return text.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    },
    isCanadianPostalCode(zipCode) {
      return /^[A-Z]\d[A-Z]/i.test(zipCode);
    },
    gTagSendEvents(label, value, action, category, leadType, leadCategory, district) {
        if (window.google_tag_manager && typeof window.dataLayer !== "undefined" && typeof action !== "undefined" && typeof category !== "undefined" && typeof label !== "undefined" && typeof value !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eventTracking",
              action: action,
              event_category: category,
              event_label: label,
              value: value,
              lead_type: leadType,
              lead_category: leadCategory,
              lead_district: district,
            });
        }
    }
  }
}
</script>

<style scoped>
.border-10 {
  border-radius: 10px !important;
}
.custom-shadow, ::v-deep .v-input__slot {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important;
}
.v-application .rounded-lg {
  border-radius: 10px!important;
}
.break-word {
  word-break: break-word;
}
::v-deep .theme--light.v-input--is-disabled, ::v-deep .theme--light.v-input--is-disabled input, ::v-deep .theme--light.v-input--is-disabled textarea, ::v-deep .theme--light.v-icon.v-icon.v-icon--disabled {
  color: initial !important;
}
::v-deep .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
  color: #4e4e4e;
}
.v-menu__content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  border-radius: 10px 10px 10px 10px !important;
}
::v-deep .v-input__prepend-outer {
  margin: 18px 9px !important;
}
::v-deep .v-input__append-outer {
  margin-top: 0px !important;
}
::v-deep .v-skeleton-loader__text {
  margin-bottom: 0px;
}
</style>
